@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');


.App {
  background-color: rgb(255, 255, 255);
}

.img-section img{
  width: 100vw;
  z-index: inherit;
  position: relative;
}

.nav-logo {
  margin-top: 0;
  margin-left: -10rem;
  width: 30vw;
  max-width: 128px;
  rotate: -10deg;
}

.section-bg {
  background-color: #e195ea;
  width: 100%;
  max-width: 900px;
}

.text-paragraph {
  /* min-width: 70vw; */
  width: 100%;
  max-width: 500px;
  margin-bottom: 1rem;
}

.title {
  margin-bottom: 1rem;
  text-align: center;
}

.home-bio {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 3rem;
  background-color: rgb(26, 0, 26);
  color: rgb(245, 239, 222);
}

.form-part {
  margin-top: 1rem;
}

.page-title {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 5rem;
}

.gallery-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-bottom: solid rgb(255, 255, 255) 1px;
}

.gallery-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  
}

.image-frame{
  padding: 1rem;
}

.banner-container{
  margin: 0 auto;
  border: 1px solid black;
  /* border-radius: 5rem; */
  width: 100%;
  padding: 2rem;
  background: rgb(87, 152, 93);
  /* margin-top: 1rem; */
  /* margin-bottom: 1rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner-title{
  font-size: 1.5rem;
}

.modal-subtitle{
  font-size: 2rem;
}

.center-omg {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .nav-logo {
    margin-top: 0;
    margin-left: 5rem;
  }
}
